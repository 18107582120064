export const kFooterLink = [
  {
    link: "https://koders.in/cancellation",
    title: "Cancellation Policy",
  },
  {
    link: "https://koders.in/cancellation",
    title: "Refund Policy",
  },
  {
    link: "https://koders.in/privacy-policy",
    title: "Privacy Policy",
  },
];
